if (process.env.NODE_ENV === "production") {
  module.exports = global.config = {
    infos: {
      server_url: "https://api.fiddle-gateway.eu/api/v1/",
      image_url: "https://api.fiddle-gateway.eu/",
    },
  };
} else {
  module.exports = global.config = {
    infos: {
      server_url: "http://127.0.0.1:8000/api/v1/",
      image_url: "http://127.0.0.1:8000/",
    },
  };
}

// module.exports = global.config = {
//   infos: {
//     server_url: "http://194.249.3.184:8000/api/v1/",
//     image_url: "http://194.249.3.184:8000/",
//   },
// };

// module.exports = global.config = {
//   infos: {
//     server_url: "https://be.fiddle-gateway-test.net/api/v1/",
//     image_url: "https://be.fiddle-gateway-test.net/",
//   },
// };
