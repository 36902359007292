import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ServerRequests from "../../../../Requests/Server";
import Authentication from "../../../../Requests/UserData";
const Location = ({ setFilters, region, setRegion, setUpdated }) => {
  const currentUser = Authentication.getCurrentUser();
  const [country, setCountry] = React.useState("");
  const [countries, setCountries] = React.useState(null);
  const [regions, setRegions] = React.useState([""]);

  const handleSelect = () => {};
  React.useEffect(() => {
    ServerRequests.get_token("home/countries/", currentUser.auth_token).then(
      (result) => setCountries(result)
    );
  }, []);

  React.useEffect(() => {
    ServerRequests.get_token("home/regions/", currentUser.auth_token)
    .then((response) => setRegions(response));
  }, [region]);

  return (
    <FormControl fullWidth>
      <Typography>Region</Typography>

      <Autocomplete
        multiple
        id="tags-outlined"
        defaultValue={region || []}
        limitTags={2}
        onChange={(event, newValue) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, region: newValue },
          }));
          setRegion(newValue);
          setUpdated((prev) => prev + 1);
        }}
        options={regions || []}
        getOptionLabel={(option) => option?.name}
        sx={{
          width: "100%",
        }}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default Location;
